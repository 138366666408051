.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../Assets/images/PicturePeople_resized.jpg");
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -2;
}

.bimgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
    z-index: -1;
}

.momentumText {
    font-weight: 500;
    text-align: center;
    text-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    color: white;
}

#momentumTime {
    font-size: 1050%;
    letter-spacing: -5px;
    line-height: 1;
    cursor: default;
}

#momentumDate {
    font-size: 337.5%;
    line-height: normal;
    cursor: default;
}

.container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.weatherHover {
    margin: 0;
    position: absolute;
    right: 140px;
    top: 50px;
}

.weatherClick {
    margin: 0;
    position: absolute;
    right: 50px;
    top: 120px;
    color: white;
}

.weatherClick > .title {
    font-size: 125%;
    text-align: end;
}

.weatherClick .vScroll {
    width: 255px;
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
    /* border-right: solid hsla(0, 0%, 100%, 0.534) 1px; */
}

.weatherClick .vScroll::-webkit-scrollbar {
    width: 0;
}

.weatherClick .vScroll .item {
    min-width: 110px;
    line-height: 110px;
    text-align: center;
    margin-right: 2px;
    margin-bottom: 15px;
    position: relative;
    right: 0px;
    top: 0px;
    cursor: default;
    /* border-right: solid hsla(0, 0%, 100%, 0.534) 1px; */
    background-color: #ffffff08;
}

.weatherClick .vScroll .item p {
    padding-left: 5px;
    padding-right: 5px;
}

.weatherContainer {
    position: absolute;
    right: 50px;
    top: 40px;
}

.weatherContainer:hover{
    cursor: pointer;
}

.weatherContainer>p {
    margin: 0;
    font-size: 125%;
    margin-top: 10px;
}

#weatherIcon {
    width: 40px;
    /* Make the Icon white */
    filter: invert(96%) sepia(99%) saturate(2%) hue-rotate(139deg) brightness(112%) contrast(100%);
    padding-right: 10px;

}

#weatherTemp {
    font-size: 1.625rem;
    font-weight: 400;
}

#weatherContainer>div {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}