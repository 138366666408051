@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap);
html {
  height: auto;
  height: initial;
}

h1 {
  text-align: center;
}

.nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav_content {
  max-width: 1024px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
      grid-template-columns: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

header {
  width: 100%;
  height: 4rem;
  /* position: fixed; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 1.5rem;
  background-color: #011C26;
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.05px solid #003B53;
}

.header_logo {
  color: #F2F2F2;
  margin: auto;
}

.header_toggle {
  font-size: 1.5rem;
  color: #F2F2F2;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  nav {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: #011C26;
    color: #F2F2F2;
    width: 100%;
    min-height: -webkit-fill-available;
    padding: 1.5rem 0;
    z-index: 100;
    transition: .5s;
  }
}

.nav_content {
  height: 100%;
  -ms-grid-rows: max-content 1fr max-content;
      grid-template-rows: -webkit-max-content 1fr -webkit-max-content;
      grid-template-rows: max-content 1fr max-content;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.nav_close {
  position: absolute;
  right: 1.5rem;
  font-size: 1.3rem;
  padding: .25rem;
  background-color: #282B3A;
  border-radius: 50%;
  cursor: pointer;
}

.nav_img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: .25rem;
  margin-bottom: .8rem;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
}

.nav_img img {
  width: 46px;
}

.nav_name {
  display: block;
  color: #F2F2F2;
}

.nav_profession {
  font-size: 0.813rem;
}

.nav_menu {
  -ms-grid-row-align: center;
  -webkit-align-self: center;
          align-self: center;
}

.nav_item {
  margin: 2.5rem 0;
}

.nav_link {
  color: #F2F2F2;
}

.nav_socials {
  padding-bottom: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
}

.nav_social-icon {
  font-size: 1.2rem;
  color: #F2F2F2;
  margin-right: 1rem;
  display: inline;
  cursor: pointer;
}

.header_dialog {
  position: absolute;
  top: 71px;
  width: 100%;
  padding: 0px 15px 0px 15px;
  border: 0.05px solid #003B53;
  border-top: none;
  text-align: justify;
  background-color: #011C26;
}

.header_dialog:focus {
  outline: none;
}

.header_dialog li {
  list-style-type: none;
  margin-left: -40px;
  padding-top: 10px;
}

.header_dialog li .nav_link {
  padding: 0;
}

.header_dialog li .nav_social-icon {
  padding: 0;
}

.header_dialog li:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #008DAF;
          text-decoration-color: #008DAF;
}

.header_dialog hr {
  width: 130%;
  margin: 0px;
  margin-top: 10px;
  margin-left: -40px;
  border: 0.005px solid #003B53;
}

.show {
  left: 0;
}

.active {
  text-decoration: underline;
  -webkit-text-decoration-color: #008daf;
          text-decoration-color: #008daf;
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }
  header {
    height: 5rem;
  }
  .header_logo, .header_toggle {
    display: none;
  }
  nav {
    width: 100%;
  }
  .nav_content {
    -ms-grid-columns: (max-content)[3];
        grid-template-columns: repeat(3, -webkit-max-content);
        grid-template-columns: repeat(3, max-content);
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -webkit-column-gap: 1rem;
            grid-column-gap: 1rem;
            column-gap: 1rem;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .nav_close, .nav_profession {
    display: none;
  }
  .nav_perfil {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .nav_img {
    width: 60px;
    height: 60px;
    margin-right: .5rem;
    margin-bottom: 0;
    -webkit-align-items: center;
            align-items: center;
  }
  .nav_img img {
    width: 50px;
  }
  .nav_list {
    display: -webkit-flex;
    display: flex;
  }
  .nav_item {
    margin: 0 .25rem;
  }
  .nav_link {
    padding: .5rem .8rem;
    border-radius: .25rem;
  }
  .nav_link:hover {
    color: #F2F2F2;
    text-decoration: underline;
    -webkit-text-decoration-color: #008DAF;
            text-decoration-color: #008DAF;
  }
  .nav_social-icon {
    padding: .5rem .8rem;
    border-radius: .25rem;
  }
  .nav_social-icon:hover {
    color: #F2F2F2;
    text-decoration: underline;
    -webkit-text-decoration-color: #008DAF;
            text-decoration-color: #008DAF;
  }
  .active {
    text-decoration: underline;
    -webkit-text-decoration-color: #008DAF;
            text-decoration-color: #008DAF;
  }
}

@media screen and (min-width: 1024px) {
  .nav_content {
    margin-left: auto;
    margin-right: auto;
  }
}

*, ::before, ::after {
  box-sizing: border-box;
}

body {
  font-family: "Mulish", sans-serif;
  font-size: 0.938rem;
  font-weight: 500;
  background-image: linear-gradient(#011C26, #002B3C);
  color: #F2F2F2;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.content {
  margin: 3rem 1rem 0rem 2rem;
}

.disabled a {
  color: #f2f2f273;
}

.disabled:hover {
  color: #f2f2f273;
  cursor: default;
  text-decoration: none !important;
}

.disabled a:hover {
  color: #f2f2f273;
  cursor: default;
  text-decoration: none;
}

button {
  background: none;
  color: #F2F2F2;
  padding: 15px;
  border: #003b53 4px solid;
  border-radius: 0% 25%;
  transition: 0.2s;
}

button:hover {
  background: #ffffff10;
  border-radius: 25% 0%;
  border-color: #07516f;
  cursor: pointer;
}

article {
  width: 70%;
  font-size: 1.5em;
  line-height: 1.5em;
  margin: auto;
}

/*# sourceMappingURL=styles.css.map */
.no_site_match_noSiteMatchContainer__Tu2up {
    text-align: center;
}
.starting_page_backgroundImage__13uBk {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/static/media/PicturePeople_resized.ab4e429e.jpg);
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -2;
}

.starting_page_bimgOverlay__2ttNb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
    z-index: -1;
}

.starting_page_momentumText__2uFwr {
    font-weight: 500;
    text-align: center;
    text-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    color: white;
}

#starting_page_momentumTime__hso5A {
    font-size: 1050%;
    letter-spacing: -5px;
    line-height: 1;
    cursor: default;
}

#starting_page_momentumDate__1tQjo {
    font-size: 337.5%;
    line-height: normal;
    cursor: default;
}

.starting_page_container__335lk {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.starting_page_weatherHover__WSL09 {
    margin: 0;
    position: absolute;
    right: 140px;
    top: 50px;
}

.starting_page_weatherClick__3D3lJ {
    margin: 0;
    position: absolute;
    right: 50px;
    top: 120px;
    color: white;
}

.starting_page_weatherClick__3D3lJ > .starting_page_title__3DjqB {
    font-size: 125%;
    text-align: end;
}

.starting_page_weatherClick__3D3lJ .starting_page_vScroll__1YfkP {
    width: 255px;
    display: -webkit-flex;
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
    /* border-right: solid hsla(0, 0%, 100%, 0.534) 1px; */
}

.starting_page_weatherClick__3D3lJ .starting_page_vScroll__1YfkP::-webkit-scrollbar {
    width: 0;
}

.starting_page_weatherClick__3D3lJ .starting_page_vScroll__1YfkP .starting_page_item__1d9Ns {
    min-width: 110px;
    line-height: 110px;
    text-align: center;
    margin-right: 2px;
    margin-bottom: 15px;
    position: relative;
    right: 0px;
    top: 0px;
    cursor: default;
    /* border-right: solid hsla(0, 0%, 100%, 0.534) 1px; */
    background-color: #ffffff08;
}

.starting_page_weatherClick__3D3lJ .starting_page_vScroll__1YfkP .starting_page_item__1d9Ns p {
    padding-left: 5px;
    padding-right: 5px;
}

.starting_page_weatherContainer__lLAl2 {
    position: absolute;
    right: 50px;
    top: 40px;
}

.starting_page_weatherContainer__lLAl2:hover{
    cursor: pointer;
}

.starting_page_weatherContainer__lLAl2>p {
    margin: 0;
    font-size: 125%;
    margin-top: 10px;
}

#starting_page_weatherIcon__2iOLH {
    width: 40px;
    /* Make the Icon white */
    -webkit-filter: invert(96%) sepia(99%) saturate(2%) hue-rotate(139deg) brightness(112%) contrast(100%);
            filter: invert(96%) sepia(99%) saturate(2%) hue-rotate(139deg) brightness(112%) contrast(100%);
    padding-right: 10px;

}

#starting_page_weatherTemp__1eeox {
    font-size: 1.625rem;
    font-weight: 400;
}

#starting_page_weatherContainer__lLAl2>div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
